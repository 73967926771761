import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { Line, Polar, Bar } from 'react-chartjs-2';
import moment from 'moment';
import Text from '../components/forms/fields/text';
import {
    Container,
    Row,
    Col,
    Button,
} from 'reactstrap';


class HomePage extends Component {
    constructor(props) {
        super(props);


        this.state = {
            emails: [],
        };
    }


    componentDidMount() {
        //this.props.handleLoader(true);
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }
    }


    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }
    }


    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });



        this.props.socketIOClient.on('adminNewsletters', (data) => {
            console.log(data);

            this.setState({emails: data});
        });

        this.props.socketIOClient.emit('adminNewsletters', {  });
    }


    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("adminNewsletters");
    }


    render() {
      

        return (
            <div className="content">
                {

                    (!this.props.uData && !localStorage.uData) && <Redirect to='/login' />

                }
                <Container fluid>

                    <div className="panel ">
                        <div className="panel-title">
                            <h1>{this.props.translate('Dashboard')}</h1>
                            <h6>{this.props.translate('Newsletters')}</h6>

                        </div>


                    </div>

                    <Row>


                        <Col lg="12" className={"table table-no-image"}>
                            <textarea style={{width: '100%', height: '70vh', border: 0, padding: 20}} value={this.state.emails.filter((item) => item && item.indexOf('@') !== -1 && item.indexOf('.') !== -1).map((item, idx) => {
                                        return (
                                                    item
                                        )
                                    }).join('\n')}>

                            </textarea>

                        </Col>
                    </Row>



                </Container>
            </div>



        );
    }
}

export default PageWithLayout(HomePage);